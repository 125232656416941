import { getEnv } from 'services/apiEnv.service';
import { get, post, del, put, patch } from 'services/request.service';
import { GetUserTaskDashboardType } from './task-dashboard.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/task-dashboard-management`;

export const getUserTaskDashboard = async ({ params }: any, pageParams: number) => {
  const response = await get({
    url: `${BASE_URL}/user-task-dashboard`,
    params: { ...params, pageParams }
  });

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    columnData: JSON.parse(response.headers.columndata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getTaskDashboardFilterDefaults = async () => {
  return (await get({ url: `${BASE_URL}/filter-defaults` })).data;
};
