export const REQUESTS_QUERY_FILTERS = {
  domains: [],
  depts: [],
  subDepts: [],
  areas: [],
  teams: [],
  regions: [],
  priorities: [],
  statusTypes: [],
  subStatusTypes: [],
  titleSearch: '',
  leadIds: [],
  requesterIds: [],
  reviewerIds: [],
  requestIds: [],
  classificationIds: [],
  teamMemberIds: [],
  showCompleted: null,
  forReview: null,
  top10: null,
  noPM: null,
  onHold: null,
  requiresValidation: false,
  priorityScoreLow: '',
  priorityScoreHigh: '',
  requestComplexities: [],
  requestDateFrom: null,
  requestDateTo: null,
  RIDateFrom: null,
  RIDateTo: null,
  PIDateFrom: null,
  PIDateTo: null,
  requestCategories: [],
  noRIDate: false,
  noPIDate: false,
  noReviewerIds: false,
  noLeadIds: false,
  escalatedProject: false,
  scheduleRisk: false,
  seniorStaffPriorityUS: false,
  seniorStaffPriorityEU: false,
  s4HANADualDevelopment: false,
  percentCompleteLow: '',
  percentCompleteHigh: '',
  requestHealthIds: [],
  referenceIds: [],
  businessImpacts: [],
  escalationRequested: null,
  escalationApproved: null,
  projectTypeId: null,
  documentTypeId: [],
  multiSearch: '',
  plannedEndDateFrom: null,
  plannedEndDateTo: null,
  plannedStartDateFrom: null,
  plannedStartDateTo: null,
  requestTitleSearch: '',
  showMilestonesOnly: false,
  showReadyTasks: true,
  taskStatusIds: [],
  taskWorkItemTypeIds: []
};

export const REQUESTS_INTAKE_FILTERS = {
  depts: [],
  statusTypes: [],
  subStatusTypes: [],
  requestCategories: [],
  requestUpdateCategories: [],
  classificationIds: [],
  businessImpacts: [],
  priorityScoreLow: '',
  priorityScoreHigh: '',
  RIDateFrom: null,
  RIDateTo: null,
  regions: [],
  multiSearch: '',
  requestTitleSearch: ''
};

export const REQUESTS_QUERY_SORT = { ['sort.col']: 'id', ['sort.dir']: 2 };

export const REQUEST_TYPES = {
  project: 15,
  print: 16,
  signage: 17,
  clinicalEval: 18,
  automation: 19
};

export const BRANDING_REQUEST_TYPES = {
  project: '15',
  print: '16',
  signage: '17',
  clinicalEval: '18',
  automation: '19'
};

export const SHIPPING_CRITERIAS = {
  willCall: 3,
  deliverToBuilding: 2,
  shipToAddress: 1
};

export const REQUEST_MAX_STEPS = {
  [BRANDING_REQUEST_TYPES.project]: 6,
  [BRANDING_REQUEST_TYPES.print]: 5,
  [BRANDING_REQUEST_TYPES.signage]: 3,
  [BRANDING_REQUEST_TYPES.clinicalEval]: 7,
  [BRANDING_REQUEST_TYPES.automation]: 5
};

export const ATS_REQUEST_TYPES = {
  website: '1',
  software: '3',
  infrastructureSoftware: '20',
  hardware: '6',
  other: '8',
  documentation: '10',
  informationSecurity: '11',
  applications: '3',
  evaluation: '25'
};

export const ESCALATION_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];
