export const TASK_DASHBOARD_QUERY_SORT = { ['sort.col']: 'plannedStartDate', ['sort.dir']: 2 };

export const TASK_DASHBOARD_QUERY_FILTERS = {
  requestIds: [],
  multiSearch: '',
  plannedEndDateFrom: null,
  plannedEndDateTo: null,
  plannedStartDateFrom: null,
  plannedStartDateTo: null,
  requestTitleSearch: '',
  showMilestonesOnly: false,
  showReadyTasks: false,
  taskStatusIds: [],
  taskWorkItemTypeIds: []
};
