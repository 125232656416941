import { useQuery, useQueryClient, useInfiniteQuery } from 'react-query';
import { TaskDashboardAPI } from 'api';

export const useGetUserTaskDashboard = ({
  params,
  ...config
}: {
  params: { [key: string]: string | number | boolean | null } | {};
}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await TaskDashboardAPI.getUserTaskDashboard(params, pageParam);

    queryClient.setQueryData('task-dashboard-headers', headers);

    return items;
  };

  return useInfiniteQuery(['task-dashboard', params], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};

export const useGetTaskTemplateFilterDefaults = () => {
  return useQuery(
    'task-dashboard-filter-defaults',
    async () => {
      return await TaskDashboardAPI.getTaskDashboardFilterDefaults();
    },
    {
      staleTime: 1000 * 60 * 5,
      cacheTime: 0
    }
  );
};
