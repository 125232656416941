import * as queries from './task-dashboard.queries';
// import * as mutations from './task-dashboard.mutations';
import * as api from './task-dashboard.api';
import * as constants from './task-dashboard.constants';

export * from './task-dashboard.api';
export * from './task-dashboard.queries';
export * from './task-dashboard.constants';
// export * from './task-dashboard.mutations';

export default { ...api, ...queries, ...constants };
