import { useMutation, UseMutationOptions } from 'react-query';
import { RequestAPI } from 'api';
import { ALRequestSubmissionPayload } from 'components/WizardTypes/Wizard.types';
import { use } from 'chai';

export const useAddBrandingRequest = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.addBrandingRequest, { ...config });
};

export const useSubmitFacilitiesRequest = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.submitFacilitiesRequest, { ...config });
};

export const useAddATSRequest = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.addATSRequest, { ...config });
};

export const useAddALRequest = (
  config?: UseMutationOptions<
    any, // this is the data that is returned
    unknown,
    ALRequestSubmissionPayload,
    unknown
  >
) => {
  return useMutation(RequestAPI.addALRequest, { ...config });
};

export const useSubmitAppliedLearningRequest = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.submitAppliedLearningRequest, { ...config });
};

export const useAddRequestAttachments = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.addRequestAttachments, { ...config });
};

export const useDeleteRequestAttachments = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.deleteRequestAttachments, { ...config });
};

export const useDeleteBulkAttachments = (config?: UseMutationOptions) => {
  return useMutation(Promise.all);
};

export const useUpdateViewColumn = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.updateViewColumn, { ...config });
};

export const useResetViewColumn = (config?: UseMutationOptions) => {
  return useMutation((id: any) => RequestAPI.resetViewColumns(id), { ...config });
};

export const useUpdateShipping = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.updateShipping, { ...config });
};

export const useUpdateRequest = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.updateRequest, { ...config });
};

export const useUpdateBrandingDetails = (config?: UseMutationOptions) => {
  return useMutation(RequestAPI.updateBrandingDetails, { ...config });
};

export const useUpdateBrandingSignage = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateBrandingSignagePrintDetail, { ...config });
};

export const useAddBrandingSignagePrintDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addBrandingSignagePrintDetail, { ...config });
};

export const useDeleteBrandingSignagePrintDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteBrandingSignagePrintDetail, { ...config });
};

export const useUpdateBrandingPrintDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateBrandingPrintDetail, { ...config });
};

export const useAddBrandingPrintDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addBrandingPrintDetail, { ...config });
};

export const useDeleteBrandingPrintDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteBrandingPrintDetail, { ...config });
};

export const useUpdateRequestEvent = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateRequestEvent, { ...config });
};

export const useDeleteRequestEvent = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteRequestEvent, { ...config });
};

export const useAddRequestEvent = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addRequestEvent, { ...config });
};

export const useDeleteRequestAttachment = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteRequestAttachment, { ...config });
};

export const useUpdateProjectUpdateDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateProjectUpdateDetail, { ...config });
};

export const useAddProjectUpdateDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addProjectUpdateDetail, { ...config });
};

export const useDeleteProjectUpdateDetail = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteProjectUpdateDetail, { ...config });
};

export const useUpdateRequestDetailNotes = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateRequestDetailNotes, { ...config });
};

export const useUpdateJustificationNotes = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateJustificationNotes, { ...config });
};

export const useAddRequestDetailNotes = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addRequestDetailNotes, { ...config });
};

export const useAddCharterDetails = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addCharterDetails, { ...config });
};

export const useUpdateCharterDetails = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateCharterDetails, { ...config });
};

export const useAddLearningCharterDetails = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addLearningCharterDetails, { ...config });
};

export const useUpdateLearningCharterDetails = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateLearningCharterDetails, { ...config });
};

export const useAddRequestSubscription = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.addRequestSubscription, { ...config });
};

export const useDeleteRequestSubscription = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteRequestSubscription, { ...config });
};

export const useDeleteRequest = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.deleteRequest, { ...config });
};

export const useUpdateApproverChangeLogs = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateApproverChangeLogs, { ...config });
};

export const useResubmitRequest = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.resubmitRequest, { ...config });
};

export const useResubmitLearningRequest = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.resubmitLearningRequest, { ...config });
};

export const useUpdateRequestTimeline = (config?: UseMutationOptions<any>) => {
  return useMutation(RequestAPI.updateRequestTimeline, { ...config });
};
