import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import { TaskTemplatePasteDTO } from './request-tasks.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/task`;

export const getAllTasks = async ({ requestId, params }: TAPIArgs = {}) => {
  const response = await get({
    url: `${BASE_URL}/${requestId}/tasks`,
    params: { ...params }
  });

  return {
    items: response.data,
    filterCount: JSON.parse(response.headers.filtercount),
    columnData: JSON.parse(response.headers.columndata)
  };
};

export const getTaskImplementationMethod = async ({ requestId }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/${requestId}/implementation-methodology` })).data;
};

export const getTaskFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/task-filter-enums` })).data;
};

export const getTaskTeams = async ({ requestId, taskId }) => {
  return (await get({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/available-teams` })).data;
};

export const getTaskNotes = async ({ requestId, taskId }) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/notes`,
      params: { requestId: requestId, taskId: taskId }
    })
  ).data;
};

export const getTaskDetails = async ({ requestId, taskId }) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}`
    })
  ).data;
};

export const searchDependencyOptions = (requestId, taskId, input: string, panelExistingPrereqs) => {
  return get({
    url: `${BASE_URL}/${requestId}/tasks/${taskId}/dependency-candidates-panel`,
    params: {
      requestId: requestId,
      taskId: taskId,
      searchTerm: input,
      panelExistingPrereqs: panelExistingPrereqs
    }
  });
};

export const searchSprintOptions = (requestId, taskId, input: string, panelExistingSprints) => {
  return get({
    url: `${BASE_URL}/${requestId}/tasks/${taskId}/search-sprints-panel`,
    params: {
      requestId: requestId,
      taskId: taskId,
      searchNumber: input,
      panelExistingSprints: panelExistingSprints
    }
  });
};

export const getTaskTeamMembers = async ({ requestId, taskId }) => {
  return (await get({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/available-team-members` }))
    .data;
};

export const getTaskDetailsPercent = async ({
  requestId,
  taskId,
  statusTypeId,
  progressPercent
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/percent-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        statusTypeId: statusTypeId,
        progressPercent: progressPercent
      }
    })
  ).data;
};

export const getTaskDetailsStatus = async ({
  requestId,
  taskId,
  statusTypeId,
  progressPercent
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/status-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        statusTypeId: statusTypeId,
        progressPercent: progressPercent
      }
    })
  ).data;
};

export const getTaskDetailsDuration = async ({
  requestId,
  taskId,
  statusTypeId,
  plannedStartDate,
  plannedEndDate,
  duration
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/duration-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        statusTypeId: statusTypeId,
        plannedStartDate: plannedStartDate,
        plannedEndDate: plannedEndDate,
        duration: duration
      }
    })
  ).data;
};

export const getTaskDetailsPSD = async ({
  requestId,
  taskId,
  statusTypeId,
  plannedStartDate,
  plannedEndDate,
  duration
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/planned-start-date-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        statusTypeId: statusTypeId,
        plannedStartDate: plannedStartDate,
        plannedEndDate: plannedEndDate,
        duration: duration
      }
    })
  ).data;
};

export const getTaskDetailsPED = async ({
  requestId,
  taskId,
  statusTypeId,
  plannedStartDate,
  plannedEndDate,
  duration
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/planned-end-date-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        statusTypeId: statusTypeId,
        plannedStartDate: plannedStartDate,
        plannedEndDate: plannedEndDate,
        duration: duration
      }
    })
  ).data;
};

export const getTaskDetailsDependency = async ({
  requestId,
  taskId,
  prereqsAdd,
  prereqsDelete,
  plannedStartDate,
  plannedEndDate,
  duration
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/dependencies-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        prereqsAdd: prereqsAdd,
        prereqsDelete: prereqsDelete,
        plannedStartDate: plannedStartDate,
        plannedEndDate: plannedEndDate,
        duration: duration
      }
    })
  ).data;
};

export const getTaskDetailsTeam = async ({
  requestId,
  taskId,
  teamsAdd,
  teamsDelete,
  panelTeamMembers
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/teams-values`,
      params: {
        requestId: requestId,
        taskId: taskId,
        teamsAdd: teamsAdd,
        teamsDelete: teamsDelete,
        panelTeamMembers: panelTeamMembers
      }
    })
  ).data;
};

export const getTaskDetailsTeamsOptions = async ({ requestId, taskId, panelExistingTeams }) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/available-teams-panel`,
      params: { requestId: requestId, taskId: taskId, panelExistingTeams: panelExistingTeams }
    })
  ).data;
};

export const getTaskDetailsTeamMembersOptions = async ({
  requestId,
  taskId,
  panelTeams,
  panelExistingTeamMembers
}) => {
  return (
    await get({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/available-team-members-panel`,
      params: {
        requestId: requestId,
        taskId: taskId,
        panelTeams: panelTeams,
        panelExistingTeamMembers: panelExistingTeamMembers
      }
    })
  ).data;
};

export const searchDependency = (requestId: number, taskId: number, input: string) => {
  return get({
    url: `${BASE_URL}/${requestId}/tasks/${taskId}/dependency-candidates`,
    params: { searchTerm: input }
  });
};

export const searchFilterAssignedTeams = (requestId, input: string) => {
  return get({
    url: `${BASE_URL}/${requestId}/assigned-teams`,
    params: { requestId: requestId, searchTerm: input }
  });
};

export const searchFilterAssignedTeamMembers = (requestId, input: string) => {
  return get({
    url: `${BASE_URL}/${requestId}/assigned-team-members`,
    params: { requestId: requestId, searchTerm: input }
  });
};

export const searchFilterSprints = (requestId, input) => {
  return get({
    url: `${BASE_URL}/${requestId}/search-sprints`,
    params: { requestId: requestId, searchNumber: input }
  });
};

export const searchTaskNames = (requestId, input: string) => {
  return get({
    url: `${BASE_URL}/${requestId}/search-tasks`,
    params: { requestId: requestId, searchTerm: input }
  });
};

export const updateTaskPercent = async ({ requestId, taskId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/Percent`, data, headers }))
    .data;
};

export const updateTaskStatus = async ({ requestId, taskId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/Status`, data, headers }))
    .data;
};

export const updateTaskName = async ({ requestId, taskId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/Name`, data, headers }))
    .data;
};

export const updateTaskWorkItemType = async ({ requestId, taskId, data, headers }: any) => {
  return (
    await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/TaskWorkItemType`, data, headers })
  ).data;
};

export const addTask = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/tasks`, data })).data;
};

export const addTaskNote = async ({ requestId, taskId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/notes`, data })).data;
};

export const updateTaskPlannedStartDate = async ({ requestId, taskId, data, headers }: any) => {
  return (
    await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/PlannedStartDate`, data, headers })
  ).data;
};

export const updateTaskPlannedEndDate = async ({ requestId, taskId, data, headers }: any) => {
  return (
    await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/PlannedEndDate`, data, headers })
  ).data;
};

export const updateTaskDuration = async ({ requestId, taskId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/Duration`, data, headers }))
    .data;
};

export const updateTaskSprints = async ({ requestId, taskId, data, headers }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/sprints`, data, headers }))
    .data;
};

export const updateTaskTeam = async ({ requestId, taskId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/teams`,
      data
    })
  ).data;
};

export const updateTaskTeamMembers = async ({ requestId, taskId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/team-members`,
      data
    })
  ).data;
};

export const updateTaskNote = async ({ requestId, taskId, taskNoteId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/notes/${taskNoteId}`,
      data
    })
  ).data;
};

export const deleteTaskNote = async ({ requestId, taskId, taskNoteId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/notes/${taskNoteId}`, data }))
    .data;
};

export const deleteTask = async ({ requestId, taskId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/tasks/${taskId}`, data })).data;
};

export const updateDependencies = async ({ requestId, taskId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/dependencies`,
      data
    })
  ).data;
};

export const indentTask = async ({ requestId, taskId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/indent`, data })).data;
};

export const outdentTask = async ({ requestId, taskId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/outdent`, data })).data;
};

export const pasteTask = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/paste`, data })).data;
};

export const moveTask = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/move`, data })).data;
};

export const updateTask = async ({ requestId, taskId, data }: any) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/${taskId}`, data })).data;
};

export const updateMethodology = async ({ requestId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/task-settings/methodology`, data, headers }))
    .data;
};

export const addMethodology = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/${requestId}/task-settings`, data })).data;
};

export const isSpecialWorkingDay = async ({ requestId, taskId, date }: any) => {
  return await get({
    url: `${BASE_URL}/${requestId}/tasks/${taskId}/is-working-day`,
    params: { date }
  });
};

export const getSpecialWorkingDays = async ({ requestId, taskId }: any) => {
  return (await get({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/special-working-days` })).data;
};

export const addSpecialWorkingDay = async ({ requestId, taskId, data }: any) => {
  return (
    await post({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/special-working-days`,
      data
    })
  ).data;
};

export const deleteSpecialWorkingDay = async ({ requestId, taskId, SwdId }: any) => {
  return (
    await del({
      url: `${BASE_URL}/${requestId}/tasks/${taskId}/special-working-days/${SwdId}`
    })
  ).data;
};

export const updateMilestone = async ({ requestId, taskId, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/${requestId}/tasks/${taskId}/milestone`, data, headers }))
    .data;
};

// for task template applying template
export const pasteTaskTemplate = async ({
  requestId,
  data
}: {
  requestId: number;
  data: TaskTemplatePasteDTO;
}) => {
  return (await put({ url: `${BASE_URL}/${requestId}/tasks/paste-template`, data })).data;
};

export const deleteMultipleTasks = async ({ requestId, data }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/task-multi-delete`, data })).data;
};

export const deleteAllTasks = async ({ requestId }: any) => {
  return (await del({ url: `${BASE_URL}/${requestId}/task-multi-delete-all` })).data;
};
